<template>
	<banner-style1 :position="4"></banner-style1>
	<div class="work-details ul-none">
		<nav class="c w">
			<ul>
				<li v-for="item in list.data" :key="item.value">
					<span :class="{ on: item.value === listCurrent }"
						@click="navClick(item.value)">{{ item.label }}</span>
				</li>
			</ul>
		</nav>
		<div class="con c w">
			<winAward :list="listData.data"></winAward>
			<div class="no-more" v-if="!listData.data.length">暂无数据</div>
			<div class="page-con">
				<el-pagination background @current-change="currentChange" :page-size="query.size" :pager-count="5"
					layout="prev, pager, next" :total="listData.total" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref
	} from "vue";
	import {
		getList
	} from '@/api/competition'
	import {
		getZqDictDetail
	} from '@/api/publish'
	import winAward from '@/components/winAward'
	const bannerList = [{
		img: require("@/assets/home/adv1.png"),
	}, ];

	const listCurrent = ref(1);
	const list = reactive({
		data: []
	});
	const listData = reactive({
		data: [],
		total: 0
	})
	const query = reactive({
		page: 1,
		size: 9
	})
	getZqDictDetail({
		dictId: 5
	}).then(res => {
		list.data = res.data
		listCurrent.value = res.data[0].value

		getList({
			type: 0,
			sessionNumber: res.data[0].value,
			...query
		}).then(res => {
			listData.data = res.data.data
			listData.total = res.data.total
		})
	})

	function currentChange(e) {
		query.page = e
		getList({
			type: 0,
			sessionNumber: listCurrent.value,
			...query
		}).then(res => {
			listData.data = res.data.data
			listData.total = res.data.total
		})
	}

	function navClick(i) {
		listCurrent.value = i;
		query.page = 1
		getList({
			type: 0,
			sessionNumber: i,
			...query
		}).then(res => {
			listData.data = res.data.data
			listData.total = res.data.total
		})
	}
</script>

<style lang="scss" scoped>
	.work-details {
		padding-top: 40px;

		nav {
			font-weight: 700;
			background-color: #e5e5e5;
			padding: 20px 40px;
			box-sizing: border-box;
			margin-bottom: -55px;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 1;
					text-align: center;

					span {
						display: inline-block;
						padding: 6px 20px;
						cursor: pointer;
					}
				}

				.on {
					background-color: #59ad96;
					color: #fff;
					border-radius: 4px;
				}
			}
		}
	}

	.con {
		.con-warp {
			border-top: 1px solid #e5e5e5;
			margin: 40px 0;
		}

		.content-html {
			padding: 20px 0;
		}
	}

	.no-more {
		height: 50vh;
		font-weight: 700;
		font-size: 30px;
		color: #ccc;
		text-align: center;
		padding-top: 100px;
	}

	.page-con {
		margin: 40px 0 120px 0;

		.el-pagination {
			float: right;
		}
	}

	:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
		background-color: $themeColor !important; //修改默认的背景色
	}
</style>
